import "./style.css";
//변수명 데스크매트 deskmat 마우스패드 mousepad 티코스터 teacoster

function Colorchip({ color, onclick, selectedcolor }) {
  const colorStyle = { backgroundColor: color };

  return (
    <div
      className={`colorchip ${selectedcolor === color && "active"}`}
      style={colorStyle}
      onClick={onclick}
    ></div>
  );
}

export default Colorchip;
