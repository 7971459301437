import "./Order.css";
import "./Product.css";

//데스크매트 dmquantity, 마우스패드 mpquantity, 컵받침 cquantity
//배송비 deliveryfee 총가격 totalprice

function Product({ name, unitPrice, product, setProduct, setCQuantity }) {
  //   console.log("name : ", name);
  //   console.log("unitPrice : ", unitPrice);
  if (name === "컵받침") {
    setCQuantity(product.quantity);
  }
  const decreaseQuantity = () => {
    if (product.quantity >= 1) {
      setProduct((state) => {
        return {
          ...state,
          quantity: product.quantity - 1,
        };
      });
    }
  };
  const increaseQuantity = () => {
    console.log("increaseQuantity click");
    if (product.quantity < 9) {
      setProduct((state) => {
        return {
          ...state,
          quantity: product.quantity + 1,
        };
      });
    }
  };
  const price = product.quantity * unitPrice;

  return (
    <div className="product">
      <div className="productname">{name}</div>
      <div className="productcolorname">{product.color.name}</div>
      <div className="productquantitywrapper">
        <div className="productbuttonlist">
          <button onClick={decreaseQuantity}>-</button>
          <div className="productquantity">{product.quantity}</div>
          <button onClick={increaseQuantity}>+</button>
        </div>

        <div>{price}원</div>
      </div>
    </div>
  );
}

export default Product;
