export const colors = [
  {
    color: "#EDE9E0",
    name: "1. 그래시어 화이트",
    id: 10,
  },
  {
    color: "#EEBCB5",
    name: "2. 미스티 로즈",
    id: 2,
  },
  {
    color: "#AC7B71",
    name: "3. 바이올렛 레드",
    id: 3,
  },
  {
    color: "#ACD1BF",
    name: "4. 클라우드 민트",
    id: 11,
  },
  {
    color: "#8CA6A7",
    name: "5. 스카이 블루",
    id: 4,
  },
  {
    color: "#34462C",
    name: "6. 포레스트 그린",
    id: 5,
  },
  {
    color: "#DD6A3E",
    name: "7. 에르메스 오렌지",
    id: 8,
  },
  {
    color: "#8D5A3B",
    name: "8. 돌로마이트 브라운",
    id: 9,
  },
  {
    color: "#343855",
    name: "9. 미드나이트 네이비",
    id: 7,
  },
  {
    color: "#CABAB1",
    name: "10. 올드 레이스",
    id: 1,
  },
  {
    color: "#555452",
    name: "11. 셀레나이트 그레이",
    id: 0,
  },
  {
    color: "#141414",
    name: "12. 옵시디언 블랙",
    id: 6,
  },
];
