import "./style.css";
//변수명 데스크매트 deskmat 마우스패드 mousepad 티코스터 teacoster

function Productselector({ setSelectedProduct, selectedProduct }) {
  const clickDeskmat = () => {
    setSelectedProduct("deskmat");
  };
  const clickMousepad = () => {
    setSelectedProduct("mousepad");
  };
  const clickTeacoster = () => {
    setSelectedProduct("teacoster");
  };
  return (
    <div className="productselector">
      <div
        className={`selector ${selectedProduct === "deskmat" && "active"}`}
        onClick={clickDeskmat}
      >
        데스크매트컬러
      </div>
      <div
        className={`selector ${selectedProduct === "mousepad" && "active"}`}
        onClick={clickMousepad}
      >
        마우스패드컬러
      </div>
      <div
        className={`selector ${selectedProduct === "teacoster" && "active"}`}
        onClick={clickTeacoster}
      >
        티코스터컬러
      </div>
    </div>
  );
}

export default Productselector;
