import { useState } from "react";
import Productselector from "./Productselector";
import Colorlist from "./Colorlist";
import "./style.css";
import Customcolorpicker from "./Customcolorpicker";
import { colors } from "../../Config/colors";
//변수명 데스크매트 deskmat 마우스패드 mousepad 티코스터 teacoster

function Colorpicker({ setProduct, setSelectedProduct, selectedProduct }) {
  const [pickerColor, setPickerColor] = useState("#ffffff");
  const handleChangeComplete = (color) => {
    setPickerColor(color.hex);
    setProduct((state) => {
      return { ...state, color: { ...color, color: color.hex } };
    });
  };
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const onWrapperClick = () => {
    setIsPickerVisible(false);
    console.log("clicked");
  };
  const [selectedColor, setSelectedColor] = useState(null);
  const colorchips = colors.map((color) => {
    return {
      ...color,
      onclick: () => {
        setProduct((state) => {
          return {
            ...state,
            color,
            quantity: state.quantity === 0 ? 1 : state.quantity,
          };
        });
        setSelectedColor(color.color);
      },
    };
  });
  return (
    <div className="colorpicker">
      <Productselector
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />
      <Colorlist colorchips={colorchips} selectedcolor={selectedColor} />
      {isPickerVisible && (
        <Customcolorpicker
          pickerColor={pickerColor}
          handleChangeComplete={handleChangeComplete}
          onWrapperClick={onWrapperClick}
        />
      )}
    </div>
  );
}

export default Colorpicker;
