import "./style.css";
import Colorchip from "./Colorchip";
//변수명 데스크매트 deskmat 마우스패드 mousepad 티코스터 teacoster

function Colorlist({ colorchips, selectedcolor }) {
  const Colorchips = colorchips.map((colorchip) => {
    return (
      <Colorchip
        key={colorchip.id}
        color={colorchip.color}
        onclick={colorchip.onclick}
        selectedcolor={selectedcolor}
      ></Colorchip>
    );
  });
  return <div className="colorlist">{Colorchips}</div>;
}

export default Colorlist;
