import { useEffect, useRef } from "react";
import "./style.css";

function Picture({ deskmat, mousepad, teacoster, rendererRef }) {
  const imageFileName = `\\mp\\Mousepad_${mousepad.color.id}_Deskmat_${deskmat.color.id}_Teapot_${teacoster.color.id}.png`;
  return (
    <div>
      <img
        className="picture"
        src={process.env.PUBLIC_URL + imageFileName}
      ></img>
    </div>
  );
}

export default Picture;
