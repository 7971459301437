import "./style.css";
import { SketchPicker } from "react-color";

//변수명 데스크매트 deskmat 마우스패드 mousepad 티코스터 teacoster

function Customcolorpicker({
  pickerColor,
  handleChangeComplete,
  onWrapperClick,
}) {
  console.log(onWrapperClick);
  return (
    <div className="customcolorpicker">
      <div className="sketchpicker">
        <SketchPicker
          color={pickerColor}
          onChangeComplete={handleChangeComplete}
        />
      </div>
      <div className="customcolorpicker-wrapper" onClick={onWrapperClick}></div>
    </div>
  );
}

export default Customcolorpicker;
