import { useState } from "react";
import "./Order.css";
import Product from "./Product";
//데스크매트 dmquantity, 마우스패드 mpquantity, 컵받침 cquantity
//배송비 deliveryfee 총가격 totalprice

function Order({
  deskmat,
  mousepad,
  teacoster,
  setDeskmat,
  setMousepad,
  setTeacoster,
}) {
  const deskmatTotalPrice = deskmat.quantity * deskmat.unitPrice;
  const mousepadTotalPrice = mousepad.quantity * mousepad.unitPrice;
  const teacosterTotalPrice = teacoster.quantity * teacoster.unitPrice;
  const [cquantity, setCQuantity] = useState(0);
  const orderprice =
    deskmatTotalPrice + mousepadTotalPrice + teacosterTotalPrice;
  let totalprice = deskmatTotalPrice + mousepadTotalPrice + teacosterTotalPrice;
  const deliveryfee = totalprice < 1 || cquantity >= 1 ? 0 : 3000;
  // let saleprice = totalprice >= 20000 ? totalprice * 0.9 : totalprice;
  totalprice += deliveryfee;
  // saleprice = saleprice + deliveryfee;
  //화면에 나오는 내용
  return (
    <div className="order">
      <div className="choiceproduct">
        {deskmat.quantity !== 0 && (
          <Product
            name="데스크매트"
            unitPrice={deskmat.unitPrice}
            product={deskmat}
            setProduct={setDeskmat}
          />
        )}

        {mousepad.quantity !== 0 && (
          <Product
            name="마우스패드"
            unitPrice={mousepad.unitPrice}
            product={mousepad}
            setProduct={setMousepad}
          />
        )}
        {teacoster.quantity !== 0 && (
          <Product
            name="컵받침"
            unitPrice={teacoster.unitPrice}
            product={teacoster}
            setProduct={setTeacoster}
            setCQuantity={setCQuantity}
          />
        )}
      </div>
      <div className="totalprice">
        <div className="totalpricecontent">
          <div>금액</div>
          <div>{orderprice}원</div>
        </div>
        {/* <div>제품 10%할인{saleprice}원</div> */}
        <div className="totalpricecontent">
          <div>배송비</div>
          <div>{deliveryfee}원</div>
        </div>
        <div className="totalpricecontent">
          <div>배송비 포함 총가격</div>
          <div>{totalprice}원</div>
        </div>
      </div>
    </div>
  );
}

export default Order;
