import { useState, useRef } from "react";
import Colorpicker from "./Components/Colorpicker";
import Picture from "./Components/Picture";
import Order from "./Components/Cart/Order";
import html2canvas from "html2canvas";
import { colors } from "./Config/colors";
import "./App.css";

function App() {
  const [deskmat, setDeskmat] = useState({
    color: colors[0],
    quantity: 0,
    unitPrice: 27900,
  });
  const [mousepad, setMousepad] = useState({
    color: colors[1],
    quantity: 0,
    unitPrice: 0,
  });
  const [teacoster, setTeacoster] = useState({
    color: colors[2],
    quantity: 0,
    unitPrice: 0,
  });
  const [selectedProduct, setSelectedProduct] = useState("deskmat");
  const rendererRef = useRef(null);
  const onResetHandler = () => {
    setDeskmat((state) => {
      return {
        ...state,
        quantity: 0,
        color: colors[0],
      };
    });
    setMousepad((state) => {
      return {
        ...state,
        quantity: 0,
        color: colors[1],
      };
    });
    setTeacoster((state) => {
      return {
        ...state,
        quantity: 0,
        color: colors[2],
      };
    });
  };
  let setProduct;
  if (selectedProduct === "deskmat") {
    setProduct = setDeskmat;
  } else if (selectedProduct === "mousepad") {
    setProduct = setMousepad;
  } else if (selectedProduct === "teacoster") {
    setProduct = setTeacoster;
  }
  const onSaveAs = (uri, filename) => {
    const link = document.createElement("a");
    link.href = uri;
    link.download = filename;
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const onCapture = () => {
    html2canvas(document.getElementById("image")).then((canvas) => {
      onSaveAs(canvas.toDataURL("image/png"), "일상생활연구소.png");
    });
  };

  return (
    <div className="app-wrapper">
      <div id="image" className="app">
        <Picture
          deskmat={deskmat}
          mousepad={mousepad}
          teacoster={teacoster}
          rendererRef={rendererRef}
        />
        <Colorpicker
          setProduct={setProduct}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
        <div className="buttonarea">
          <button className="resetbutton" onClick={onResetHandler}>
            Reset
            <img
              alt="reset"
              src="https://img.icons8.com/ios-glyphs/30/000000/recurring-appointment.png"
            ></img>
          </button>
          <button className="saveimagebutton" onClick={onCapture}>
            Save Image
          </button>
        </div>
        <Order
          deskmat={deskmat}
          mousepad={mousepad}
          teacoster={teacoster}
          setDeskmat={setDeskmat}
          setMousepad={setMousepad}
          setTeacoster={setTeacoster}
        />
      </div>
    </div>
  );
}

export default App;
